.home {
  position: relative;
  display: flex;
  min-height: calc(100vh - 68px);

  .hero {
    h1 {
      text-align: center;

      .yellowheading {
        color: #ffa600;
        font-size: 30px;
      }
      .whiteheading {
        color: white;
        font-size: 28px;
      }
    }
    .about {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      max-width: 850px;
      color: white;
      // z-index: 3;
      margin-bottom: 100px;
      .about__bottom {
        font-weight: 400;
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    height: 150vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
    width: 100%;
  }
}
