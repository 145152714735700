.footer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  bottom: 0;
  width: 100%;
  height: 40px;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  z-index: 3;
  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.9);
  }
  .call {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background-color: black;
    margin: 0 0 30px 15px;
  }
}
