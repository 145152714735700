picture {
  position: relative;
  flex: auto;
  width: 300px;
  margin: 0.5vw;
  img {
    width: 100%;
    // height: 100%;
    height: auto;
  }
}
.zoom {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;

  transition: opacity 1s ease-in-out;
  z-index: 1;
}

.zoom img {
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 50%;
  height: auto;
  box-sizing: border-box;
  transition: 0.5s ease-in-out;
  z-index: 2;
}

.zoom::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
}
