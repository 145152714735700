@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // box-sizing: border-box;
}

code {
  font-family: "Josefin Sans", sans-serif;
}

html {
  background: url(../pexels-fwstudio-172289.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.container {
  display: flex;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: hidden;
}
