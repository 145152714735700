.photos {
  margin-top: 100px;
}

.container {
  display: flex;
  flex-direction: column;

  .photos {
    padding: 0.5vw;
    font-size: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-flow: row wrap;
    display: flex;
  }
}

picture:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  background-image: url(../../assets/images/paslaugos_balt.svg);
  background-repeat: no-repeat;
  background-position: bottom right;
}
