@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100&display=swap);
body{margin:0;font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:"Josefin Sans", sans-serif}html{background:url(/static/media/pexels-fwstudio-172289.e66e2f38.jpg) no-repeat center center fixed;background-size:cover}.container{display:-webkit-flex;display:flex;max-width:1200px;width:100%;height:100%;margin:0 auto;padding:0 20px;box-sizing:border-box;overflow:hidden}

.footer{position:fixed;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;font-weight:500;bottom:0;width:100%;height:40px;font-size:24px;background-color:rgba(0,0,0,0.6);color:white;z-index:3}.footer a{text-decoration:none;color:rgba(255,255,255,0.9)}.footer .call{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center;height:60px;width:60px;border-radius:100%;background-color:black;margin:0 0 30px 15px}


picture{position:relative;-webkit-flex:auto;flex:auto;width:300px;margin:0.5vw}picture img{width:100%;height:auto}.zoom{background:rgba(0,0,0,0.7);width:100%;transition:opacity 1s ease-in-out;z-index:1}.zoom img{margin:auto;position:fixed;top:0;left:0;right:0;bottom:0;max-width:50%;height:auto;box-sizing:border-box;transition:0.5s ease-in-out;z-index:2}.zoom::after{content:"";position:fixed;top:0;left:0;width:100%;height:100%;background-color:black;opacity:0.7}

.container{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.container .contacts{position:relative;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-flex-direction:column;flex-direction:column;font-weight:500;font-size:22px;min-height:calc(100vh - 68px)}.container .contacts div{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-flex-direction:column;flex-direction:column;-webkit-transform:translateY(-30%);transform:translateY(-30%)}.container .contacts div h1{color:#ffa600;cursor:default}.container .contacts div p{color:rgba(255,255,255,0.8);line-height:5px;cursor:default}.container .contacts div a{text-decoration:none;color:rgba(255,255,255,0.9);line-height:40px;transition-duration:0.2s}.container .contacts div a:hover{color:#fff}.container .contacts::before{content:"";position:absolute;left:-60px;height:150vh;background-color:rgba(0,0,0,0.4);z-index:-1;-webkit-transform:rotate(20deg);transform:rotate(20deg);width:430px}

.photos{margin-top:100px}.container{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column}.container .photos{padding:0.5vw;font-size:0;display:-webkit-flex;display:flex;-webkit-flex-wrap:wrap;flex-wrap:wrap;-webkit-flex-direction:column;flex-direction:column;-webkit-flex-flow:row wrap;flex-flow:row wrap;display:flex}picture:after{content:"";position:absolute;bottom:0;left:0;margin:auto;height:100%;width:100%;background-image:url(/static/media/paslaugos_balt.41eabb74.svg);background-repeat:no-repeat;background-position:bottom right}

.home{position:relative;display:-webkit-flex;display:flex;min-height:calc(100vh - 68px)}.home .hero h1{text-align:center}.home .hero h1 .yellowheading{color:#ffa600;font-size:30px}.home .hero h1 .whiteheading{color:white;font-size:28px}.home .hero .about{font-size:20px;font-weight:600;text-align:center;max-width:850px;color:white;margin-bottom:100px}.home .hero .about .about__bottom{font-weight:400}.home::before{content:"";position:absolute;height:150vh;background-color:rgba(0,0,0,0.4);z-index:-1;width:100%}

body{margin:0;font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:"Josefin Sans", sans-serif}html{background:url(/static/media/pexels-fwstudio-172289.e66e2f38.jpg) no-repeat center center fixed;background-size:cover}.container{display:-webkit-flex;display:flex;max-width:1200px;width:100%;height:100%;margin:0 auto;padding:0 20px;box-sizing:border-box;overflow:hidden}

