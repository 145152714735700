.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .contacts {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: 500;
    font-size: 22px;
    min-height: calc(100vh - 68px);
    div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      transform: translateY(-30%);
      h1 {
        color: #ffa600;
        cursor: default;
      }
      p {
        color: rgba(255, 255, 255, 0.8);
        line-height: 5px;
        cursor: default;
      }

      a {
        text-decoration: none;
        color: rgba(255, 255, 255, 0.9);
        line-height: 40px;
        transition-duration: 0.2s;
        &:hover {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: -60px;
      height: 150vh;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: -1;
      transform: rotate(20deg);
      width: 430px;
    }
  }
}
